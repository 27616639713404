import { Injectable } from '@angular/core';
import { ToastInterface, ToastType } from '@shared/models/interfaces/toast.interface';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private time = 6000;

    private _type?: ToastType;
    private _title = '';
    private _label = 'TOAST.LABEL';
    private _from = 'bottom';
    private _var = '';
    private _list!: string[];

    private showToastSubject$ = new BehaviorSubject<boolean>(false);

    get showToast$() {
        return this.showToastSubject$.asObservable();
    }

    get type(): ToastType | undefined {
        return this._type;
    }

    get label(): string {
        return this._label;
    }

    get title(): string {
        return this._title;
    }

    get from(): string {
        return this._from;
    }

    get var(): string | undefined {
        return this._var;
    }

    get list(): string[] {
        return this._list;
    }

    show(toastInterface: ToastInterface) {
        this.set(toastInterface);
        setTimeout(() => {
            this.showToastSubject$.next(true);
        }, 10);

        timer(this.time).subscribe(() => {
            this.hide();
        });
    }

    hide() {
        this.showToastSubject$.next(false);
    }

    set(toastInterface: ToastInterface) {
        this._type = toastInterface.type;
        if (toastInterface.title) {
            this._title = toastInterface.title;
        }
        if (toastInterface.label) {
            this._label = toastInterface.label;
        }
        if (toastInterface.from) {
            this._from = toastInterface.from;
        }
        if (toastInterface.var) {
            this._var = toastInterface.var;
        }
        if (toastInterface.list) {
            this._list = toastInterface.list;
        }
    }
}
